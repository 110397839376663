body,
html {
  font-family: 'Courier New', Courier, monospace;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eee;
}

.App-logo {
  height: 30vmin;
}

.App-header {
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  flex: 1;
  display: flex;
}

.App-header > *,
.App-main > *,
.App-footer > * {
  padding: 15px;
}

.App-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
